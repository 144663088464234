<template>
  <div>
    <SectionTitle title="Dados Básicos" />
    <!-- <v-row dense>
      <v-col
        cols="12"
        md="8"
      >
        <v-text-field
          v-model="vendor.first_name"
          label="Nome"
          placeholder="Nome"
          outlined
          rounded
          dense
          hide-details
        />
      </v-col>
    </v-row> -->
    <v-row dense>
      <v-col
        cols="12"
        md="4"
      >
        <v-text-field
          v-model="vendor.company_name"
          label="Razão Social"
          placeholder="Razão Social"
          outlined
          rounded
          dense
          hide-details
        />
      </v-col>
      <v-col
        cols="12"
        md="4"
      >
        <v-text-field
          v-model="vendor.trading_name"
          label="Nome Fantasia"
          placeholder="Nome Fantasia"
          outlined
          rounded
          dense
          hide-details
        />
      </v-col>
    </v-row>
    <v-row dense>
      <v-col
        cols="12"
        md="8"
      >
        <v-text-field
          v-model="vendor.first_email"
          label="Email"
          placeholder="Email"
          outlined
          rounded
          dense
          hide-details
        />
      </v-col>
      <!-- <v-col
        cols="12"
        md="4"
      >
        <v-text-field
          v-model="vendor.first_contact"
          label="Contato"
          placeholder="Contato"
          outlined
          rounded
          dense
          hide-details
        />
      </v-col> -->
    </v-row>
    <v-row dense>
      <v-col
        cols="12"
        md="4"
      >
        <v-text-field
          v-model="vendor.cnpj"
          v-mask="'##.###.###/####-##'"
          label="CNPJ"
          placeholder="Número do CNPJ"
          outlined
          rounded
          dense
          hide-details
        />
      </v-col>
      <v-col
        cols="12"
        md="4"
      >
        <v-select
          v-model="vendor.segment_id"
          label="Segmento"
          :items="segments"
          item-text="segment"
          item-value="id"
          outlined
          rounded
          dense
          hide-details
        />
      </v-col>
    </v-row>
    <v-row dense>
      <v-col
        cols="12"
        md="8"
      >
        <v-text-field
          v-model="vendor.site"
          label="Site"
          placeholder="Digite o site"
          outlined
          rounded
          dense
          hide-details
        />
      </v-col>
    </v-row>
    <v-row dense>
      <v-col
        cols="12"
        md="8"
      >
        <v-textarea
          v-model="vendor.note"
          label="Observação"
          auto-grow
          outlined
          dense
          rounded
          rows="10"
          row-height="15"
        />
      </v-col>
    </v-row>

    <SectionTitle title="Contatos" />
    <ContactCard
      v-for="(contact, idx) in vendor.contacts"
      :key="idx"
      :contact="contact"
      @removeContact="removeContact(contact)"
    />
    <v-row
      class="mt-3"
      dense
    >
      <v-col
        cols="12"
        md="5"
      >
        <v-btn
          rounded
          class="primary"
          @click="addContact"
          v-text="'Adicionar contato'"
        />
      </v-col>
    </v-row>

    <SectionTitle title="Endereços" />
    <LocationCard
      v-for="(location, idx) in vendor.locations"
      :key="idx"
      :location="location"
      @removeLocation="removeLocation(location)"
    />
    <v-row dense>
      <v-col
        cols="12"
        md="5"
      >
        <v-btn
          rounded
          class="primary"
          @click="addLocation"
          v-text="'Adicionar endereço'"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  components: {
    SectionTitle: () => import("@/components/SectionTitle"),
    ContactCard: () => import("./ContactCard"),
    LocationCard: () => import("./LocationCard")
  },
  props: {
    vendor: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    segments: []
  }),
  mounted() {
    this.getSegments()
  },
  methods: {
    addContact() {
      this.vendor.contacts.push({phones: [{number: ''}]})
    },
    removeContact(contact) {
      let idx = this.vendor.contacts.indexOf(contact)
      const contactToRemove = []
      if (this.vendor?.contactToRemove ) {
        contactToRemove.push(contact.id)
        this.vendor.contactToRemove = [...this.vendor.contactToRemove, ...contactToRemove]
      }else {
        this.vendor.contactToRemove = [contact.id]
      }
      this.vendor.contacts.splice(idx, 1)
    },
    addLocation() {
      this.vendor.locations.push({
        cep:'',
        address:'',
        number:'',
        district:'',
        uf:'',
        city:'',
      })
    },
    removeLocation(location) {
      let idx = this.vendor.locations.indexOf(location)
      const addressToRemove = []
      if (this.vendor?.addressToRemove ) {
        addressToRemove.push(location.id)
        this.vendor.addressToRemove = [...this.vendor.addressToRemove, ...addressToRemove]
      }else {
        this.vendor.addressToRemove = [location.id]
      }
      this.vendor.locations.splice(idx, 1)
    },
    getSegments() {
      this.$api.get('segments')
        .then(res => {
          this.segments = res.data.data
        })
        .catch(err => {
          console.error(err)
        })
    }
  }
};
</script>